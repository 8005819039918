import {
  DevicesUnpairedQuery,
  EdgeDeviceQuery,
} from '@/graphql/generated/operations'

interface OptionI {
  label: string
  value: string
}

type Options = Array<OptionI>

export interface FormInputsIProps {
  name: string
  devices?: { label: string; value: string }[]
}

export const getDeviceOptions = (
  devicesData: DevicesUnpairedQuery
): Options => {
  const edges = devicesData?.devices?.edges
  if (!edges) return []
  return (
    [...edges]
      ?.sort((a, b) =>
        a?.node?.facility?.name.localeCompare(b?.node?.facility?.name)
      )
      .map((f) => ({
        label: `${f?.node?.name} - ${f?.node?.floor.name} - ${f?.node?.facility?.name}`,
        value: f?.node?.id,
      })) || []
  )
}

export const getEdgeDeviceOptions = (edgeData: EdgeDeviceQuery): Options => {
  const edges = edgeData?.edgeDevice?.devices?.edges
  if (!edges) return []
  return (
    [...edges]
      ?.sort((a, b) =>
        a?.node?.facility?.name.localeCompare(b?.node?.facility?.name)
      )
      .filter((f) => !f?.node?.archived)
      .map((f) => ({
        label: `${f?.node?.name} - ${f?.node?.floor.name} - ${f?.node?.facility?.name}`,
        value: f?.node?.id,
      })) || []
  )
}
