import { useCreateGateway } from '../hooks/useCreateGateway'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'
import { MdOutlineRouter } from 'react-icons/md'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { CreateEdgeDeviceInput } from '@/graphql/generated/schemas'

import { FormInputsIProps } from '../utils/utils'

interface CreateGatewayModalIProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateGatewayModal = ({
  isOpen,
  onClose,
}: CreateGatewayModalIProps) => {
  const { shouldEnableCreateGateway } = usePermissions()
  const { showSuccess, showError } = useToasts()
  const { devicesOptions, create, isCreateLoading } = useCreateGateway()

  const { handleSubmit, register, control, errors } = useForm<FormInputsIProps>(
    {
      reValidateMode: 'onChange',
    }
  )
  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const name = values.name?.trim()
    const deviceIds = values.devices?.map((d) => d.value) || []
    const formData: CreateEdgeDeviceInput = { name, deviceIds }

    try {
      await create(formData)
      showSuccess('Successfully created Gateway')
      onClose()
    } catch (error) {
      showError(error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <MdOutlineRouter size='32px' />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={2}
            >
              Create Gateway
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <Box mb={3}>
              <FormInputControl
                data-testid='CreateGatewayModal:name'
                errorMessage={errors.name?.message}
                id='name'
                inputRef={register({
                  required: 'Gateway name is required',
                })}
                isDisabled={isCreateLoading}
                label='Gateway Name'
                placeholder='Enter a name for this gateway'
              />
            </Box>
            <Box mb={3}>
              <FormInputSelectControl
                closeMenuOnSelect={false}
                control={control}
                data-testid='CreateGatewayModal:devices'
                defaultValue={null}
                id='devices'
                inputRef={register()}
                isClearable
                isDisabled={isCreateLoading}
                isMulti
                isSearchable
                label='Devices'
                options={devicesOptions}
                placeholder='Add devices'
              />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            data-testid='CreateGatewayModal:save'
            isDisabled={!shouldEnableCreateGateway || isCreateLoading}
            isLoading={isCreateLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving'
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
